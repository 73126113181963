import * as moleculeImageHelper from './utils/moleculeImageHelper.js'
import React from 'react'
import ReactDOM from 'react-dom'
import { embeddedComponentStore } from '@/stores/embeddedComponentStore'

if (typeof window.CDD === 'undefined') {
  window.CDD = {}
}

window.CDD.moleculeImageHelper = moleculeImageHelper

// avoid calling this directly, as the embeddedComponentStore can handle most cases automatically.
function renderReactComponents(root = 'body') {
  embeddedComponentStore.handleChanges({ root, ignoreUnchangedElements: false })
}

window.renderReactComponents = renderReactComponents
window.ReactDOM = window.ReactDOM || ReactDOM

$(function () {
  renderReactComponents()
})
